import getConfig from 'next/config';

import { isTelemundoVertical } from 'lib/vertical';
import BaseAdPath from './BaseAdPath.json';
import { BREAKPOINTS } from './Breakpoints';

const stripHyphens = (str) => str.replace(/-/g, '');
const stripHyphensSlashes = (str) => str.replace(/[/-]/g, '');

const isHomepage = (currentPath) => /^\/(\?.*|$)/.test(currentPath);
const isFront = (pageView) => pageView === 'front';
const isTodayArticle = (pageView, vertical) => pageView === 'article' && vertical === 'today';

function buildAdUnitPathFromTaxonomy(props, dfpAdUnit) {
  const {
    currentPath = '',
    pageView,
    vertical,
    section,
    topic,
    subTopic,
  } = props;

  let gptAdUnitPath = dfpAdUnit;

  gptAdUnitPath += BaseAdPath[vertical];

  if (isHomepage(currentPath)) {
    gptAdUnitPath += '/homepage';
  }

  if (section) {
    // news is not recognized as a section...use the subsection instead
    if (vertical === 'news' && section === 'news') {
      gptAdUnitPath += `/${topic}`;
    } else {
      gptAdUnitPath += `/${section}`;
    }
  }

  if (isTodayArticle(pageView, vertical)) {
    if (topic) {
      gptAdUnitPath += `/${topic}`;
    }

    if (subTopic) {
      gptAdUnitPath += `/${subTopic}`;
    }
  }

  return stripHyphens(gptAdUnitPath);
}

function buildPreprocessors({ canonicalUrl, pageView }, provider) {
  const preprocessors = {
    xfinity: true,
  };

  if (provider !== 'gpt') {
    return preprocessors;
  }

  preprocessors.krux = {
    id: 'Hhr_tdFP',
  };

  if (pageView === 'article') {
    preprocessors.admantx = {
      url: canonicalUrl,
      id: '62263fff3cc1d07f85c7f8261a0c8f7dc096b35f59c82a713f20a9db8d562ff2',
    };
  }

  return preprocessors;
}

function getRefreshInterval() {
  const { publicRuntimeConfig: { DEFAULT_AD_REFRESH_INTERVAL } } = getConfig();

  const refreshIntervalConfigValue = parseInt(DEFAULT_AD_REFRESH_INTERVAL, 10);
  const interval = !Number.isNaN(refreshIntervalConfigValue)
    ? refreshIntervalConfigValue
    : 30;

  return interval;
}

function buildPageid(props) {
  const {
    currentPath = '',
    pageView,
    article,
    video,
    slideshow,
  } = props;

  // MPS gets confused when the pageid is a '/', so update to '/homepage'.
  if (isHomepage(currentPath)) {
    return 'homepage';
  }

  let pageid;

  switch (pageView) {
    case 'front':
      pageid = stripHyphensSlashes(currentPath.split('?')[0]);
      break;
    case 'article':
      pageid = article?.id;
      break;
    case 'video':
      pageid = video?.id;
      break;
    case 'slideshow':
      pageid = slideshow?.id;
      break;
    default:
      break;
  }

  return pageid || null;
}

function unique(arr) {
  const keys = {};

  arr.forEach((item) => {
    if (keys[item]) {
      return;
    }

    keys[item] = true;
  });

  return Object.keys(keys);
}

function combineTaxonomies(everything) {
  const everythingString = unique(everything).join('|');

  return stripHyphens(everythingString);
}

function buildTaxonomyTargeting({ article }) {
  if (!article || !article.taxonomy) {
    return null;
  }

  const {
    taxonomy: {
      primaryVertical,
      anchors,
      channels,
      courseTypes,
      cuisines,
      labels,
      recipeCategories,
      sections,
      series,
      shows,
      subtopics,
      subverticals,
      topics,
      additionalTerms,
    },
  } = article;

  const everything = []
    .concat(primaryVertical)
    .concat(anchors)
    .concat(channels)
    .concat(courseTypes)
    .concat(cuisines)
    .concat(labels)
    .concat(recipeCategories)
    .concat(sections)
    .concat(series)
    .concat(shows)
    .concat(subtopics)
    .concat(subverticals)
    .concat(topics)
    .concat(additionalTerms)
    .filter((taxonomies) => taxonomies)
    .map((thing) => thing.slug);

  return combineTaxonomies(everything);
}

function buildTargeting(props, gptAdUnitPath) {
  let { pageView } = props;
  const {
    vertical,
    show,
    label,
    series,
    targeting: additionalTargeting = {},
    isChromeless,
    article: { ecommerceEnabled, sentiment } = {},
  } = props;

  let bentoOrApp = 'bento';

  if (pageView === 'article' && isChromeless) {
    bentoOrApp = 'app';
  }

  if (ecommerceEnabled) {
    pageView = 'ecommerce';
  }

  let targetSite = 'nbcnews-bento';

  if (isTelemundoVertical(vertical)) {
    targetSite = 'telemundo-bento';
  }

  const targeting = {
    site: targetSite,
    type: `${bentoOrApp}${pageView || ''}`,
    pageid: buildPageid(props),
    platform: 'ramen',
    sentiment,
    ...additionalTargeting,
  };

  if (isTodayArticle(pageView, vertical)) {
    if (show) {
      targeting.show = stripHyphens(show);
    }

    if (label) {
      targeting.label = stripHyphens(label);
    }

    if (series) {
      targeting.series = stripHyphens(series);
    }
  }

  const taxonomyTargeting = isFront(pageView)
    ? combineTaxonomies(gptAdUnitPath.split('/').slice(2))
    : buildTaxonomyTargeting(props);

  if (taxonomyTargeting) {
    targeting.targeting = taxonomyTargeting;
  }

  return targeting;
}

function getMpsConfig(props, envVars = {}) {
  const {
    publicRuntimeConfig: {
      DFP_AD_UNIT,
      MPS_URL_TELEMUNDO,
      MPS_URL_NBCNEWS,
    },
  } = getConfig();

  const {
    vertical,
  } = props;

  const {
    provider = 'mps',
    dfpAdUnit = DFP_AD_UNIT,
    mpsUrl = isTelemundoVertical(vertical)
      ? MPS_URL_TELEMUNDO
      : MPS_URL_NBCNEWS,
  } = envVars;

  const gptAdUnitPath = buildAdUnitPathFromTaxonomy(props, dfpAdUnit);

  return {
    provider,
    preprocessors: buildPreprocessors(props, provider),
    targeting: buildTargeting(props, gptAdUnitPath),
    refreshInterval: getRefreshInterval(),
    vendors: {
      gptAdUnitPath,
      mpsUrl,
    },
    breakpoints: [
      BREAKPOINTS.l,
      BREAKPOINTS.m,
      BREAKPOINTS.s,
    ],
  };
}

export default getMpsConfig;
