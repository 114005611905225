import React from 'react';
import getConfig from 'next/config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';

import getMpsConfig from 'lib/getMpsConfig';
import mpsMapStoreToProps from 'lib/mpsMapStoreToProps';

const mapStateToProps = (state) => ({ state });

function AdsBundle({
  state,
  isArticle = false,
  vertical = '',
}) {
  const { publicRuntimeConfig: { adsBundlePath } } = getConfig();
  const mpsConfig = getMpsConfig(mpsMapStoreToProps(state));

  return mpsConfig ? (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.__mpsconfig = ${serialize(mpsConfig)};window.__isarticle = ${isArticle};window.__vertical = ${serialize(vertical)};`,
        }}
      />
      <script async src={adsBundlePath} />
    </>
  ) : null;
}

AdsBundle.propTypes = {
  state: PropTypes.shape({}).isRequired,
  isArticle: PropTypes.bool,
  vertical: PropTypes.string,
};

export default connect(mapStateToProps)(AdsBundle);
