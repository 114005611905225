import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';
import { useVertical } from 'lib/Hooks';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { SHOW_MOBILE_WEB_NAV } from 'lib/brandFeatures';
import { RouteContext } from 'lib/ContextTypes/route';
import styles from './styles.module.scss';

const SCROLL_BUFFER = 15;

function EventDayRecirc({
  headline,
  externalUrl,
  content: {
    items = [],
  },
}) {
  const { vertical } = useVertical();
  const scrollRef = useRef();
  const [{
    hideLeftScroll,
    hideRightScroll,
  }, setScrollState] = useState({ hideLeftScroll: true, hideRightScroll: false });
  const [hideHeader, setHideHeader] = useState(false);
  const [isAtTop, setIsTop] = useState(true);
  const { path } = useContext(RouteContext);

  if (items.length === 0) {
    return null;
  }

  const links = [{
    headline: headline.primary,
    url: externalUrl,
  }, ...items.map((item) => {
    if (item.type === 'article') {
      return {
        headline: item.headline.primary,
        url: item.url.primary,
      };
    }
    if (item.type === 'externalLink') {
      return {
        headline: item.text,
        url: item.url.primary,
      };
    }
    return null;
  })].filter(Boolean).map((item) => {
    let pathName = item.url;
    try {
      pathName = new URL(item.url).pathname;
    } catch {
      // ignore
    }
    return {
      ...item,
      active: pathName === path,
    };
  });

  let resizeTimeout;
  const onResize = () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      setScrollState({
        hideLeftScroll,
        hideRightScroll: scrollRef.current.scrollWidth === scrollRef.current.clientWidth,
      });
    }, 100);
  };

  const onScroll = (e) => {
    const container = e.target;
    // When towards the left
    if (container.scrollLeft < SCROLL_BUFFER) {
      return setScrollState({
        hideLeftScroll: true,
        hideRightScroll: false,
      });
    }

    // When reached towards the end on the right
    if ((container.offsetWidth + container.scrollLeft + SCROLL_BUFFER) >= container.scrollWidth) {
      return setScrollState({
        hideLeftScroll: false,
        hideRightScroll: true,
      });
    }

    // When scrolling in between
    return setScrollState({
      hideLeftScroll: false,
      hideRightScroll: false,
    });
  };

  useEffect(() => {
    let lastScrollY = 0;
    let newScrollY = 0;
    const onBodyScroll = () => {
      newScrollY = Math.round(window.scrollY);
      if (newScrollY > lastScrollY && window.scrollY > 200) {
        setHideHeader(true);
      } else {
        setHideHeader(false);
      }
      lastScrollY = newScrollY;

      setIsTop(newScrollY === 0);
    };

    setScrollState({
      hideLeftScroll: true,
      hideRightScroll: scrollRef.current.scrollWidth === scrollRef.current.clientWidth,
    });

    window.addEventListener('scroll', onBodyScroll);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('scroll', onBodyScroll);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const mobileWebNav = getFeatureConfigForBrand(SHOW_MOBILE_WEB_NAV, vertical);
  const hideGlobalorMobileNav = mobileWebNav ? styles.hideMobilenav : styles.hideHeader;

  return (
    <div
      className={classNames(styles.container, {
        [hideGlobalorMobileNav]: hideHeader,
        [styles.top]: isAtTop,
      })}
      data-activity-map="event-day-recirc-header"
      role="menu"
      data-testid="event-day-recirc"
    >
      <div
        className={styles.topBar}
      >
        <button
          aria-label="Left Scroll Button"
          title="Left Scroll Button"
          type="button"
          onClick={() => {
            const { current: scrollContainer } = scrollRef;
            scrollContainer.scrollTo({
              behavior: 'smooth',
              left: scrollContainer.scrollLeft - scrollContainer.clientWidth,
            });
          }}
          className={
            classNames(styles.leftScroll, {
              [styles.hide]: hideLeftScroll,
            })
          }
        >
          <span className="icon icon-chevron-left" />
        </button>
        <div
          className={styles.scrollContainer}
          onScroll={onScroll}
          ref={scrollRef}
          role="navigation"
        >
          {links.map(((item, i) => (
            <Link
              className={classNames(styles.link, {
                [styles.topic]: i === 0,
                [styles.current]: item.active,
              })}
              to={item.url}
              key={item.url}
              icid={`eventday_work_${i + 1}`}
            >
              <span className={styles.linkText}>{item.headline}</span>
            </Link>
          )))}
        </div>
        <button
          type="button"
          aria-label="Right Scroll Button"
          title="Right Scroll Button"
          onClick={() => {
            const { current: scrollContainer } = scrollRef;
            scrollContainer.scrollTo({
              behavior: 'smooth',
              left: scrollContainer.scrollLeft + scrollContainer.clientWidth,
            });
          }}
          className={
            classNames(styles.rightScroll, {
              [styles.hide]: hideRightScroll,
            })
          }
        >
          <span className="icon icon-chevron-right" />
        </button>
      </div>
    </div>
  );
}

EventDayRecirc.propTypes = {
  headline: PropTypes.objectOf(PropTypes.string).isRequired,
  externalUrl: PropTypes.string.isRequired,
  content: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.objectOf(PropTypes.string),
        url: PropTypes.objectOf(PropTypes.string),
      }),
    ),
  }).isRequired,
};

export { EventDayRecirc };
