/**
 * Helper function
 * @param {object} flags
 * @param {string} vertical
 * @param {string[]} itemTaxonomies
 * @param {object} eventDayList
 */
export function getShouldShowEventDayRecirc(flags, vertical, itemTaxonomies = [], eventDayList) {
  const eventDayCuratedList = flags?.[`event-day-curated-list-${vertical}`];

  const eventDayTaxonomyMatchesFront = itemTaxonomies
    .some((t) => eventDayCuratedList?.taxonomy?.includes(t));

  return eventDayList?.content?.items?.length > 0 && eventDayTaxonomyMatchesFront;
}
